/* General styling */
body, html {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 18px;
  background-color: #fff;
}

.App {
  text-align: center;
}

/* Navbar styling */
.navbar {
  background-color: #f8f9fa;
  border-bottom: 2px solid #e0e0e0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
}

.nav-link {
  color: #333;
  margin-right: 20px;
  font-size: 16px;
  transition: color 0.3s;
}

.nav-link:hover, .nav-link:focus {
  color: #007bff;
}

.navbar-brand h1 {
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s;
}

.navbar-brand h1:hover {
  color: #0056b3;
}

/* Adjustment for body padding to avoid navbar overlap */
body {
  padding-top: 76px;
}

/* Text styling */
h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

p {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
}

/* Section and image styling */
.section {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

img {
  max-width: 100%;
  height: auto;
}

.image-size {
  max-width: 120px;
  margin: 10px 0;
}


/* Offset for hash link scrolling to account for fixed navbar */
:target {
  display: block;
  position: relative;
  top: -190px; /* Adjust this value to match the height of your navbar */
  visibility: hidden;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .nav-link, .navbar-brand h1 {
    margin-right: 0;
    font-size: 0.9rem;
  }

  .image-size {
    max-width: 80px;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-content p, .legal-link {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
